import { ChakraProvider } from "@chakra-ui/react";
import './App.css';
import theme from './core/theme';
import Routes from './core/routes';
import { StateProvider } from './core/app-context';

function App() {

  return (
    <StateProvider>
      <ChakraProvider theme={theme}>
        <Routes />
      </ChakraProvider>
    </StateProvider>
  );
}

export default App;
