import { useContext, useState } from "react";
import { AppContext } from '../../core/app-context';
import {
    Button,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Grid,
    GridItem,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    Textarea,
    useDisclosure
} from "@chakra-ui/react";
import api from "../../core/api";

interface IForm {
    name: string;
    email: string;
    subject: string;
    message: string;
}

const initialForm = {
    name: {
        value: "",
        label: "Your name",
        placeholder: "Your name",
        type: "",
        errorMsg: "",
        required: true
    },
    email: {
        value: "",
        label: "Email",
        placeholder: "your@email.com",
        type: "email",
        errorMsg: "",
        required: true
    },
    subject: {
        value: "",
        label: "Subject",
        placeholder: "Hi Boipelo!",
        type: "",
        errorMsg: "",
        required: false
    },
    message: {
        value: "",
        label: "Message",
        placeholder: "I saw your online profile and...",
        type: "textarea",
        errorMsg: "",
        required: true
    }
};

export default function ContactMeModal () {

    const [appState, setAppState] = useContext(AppContext);

    const modalDisclosure = useDisclosure();
    const [dummy, setDummy] = useState(0);
    let form = appState.contactForm;

    function handleChange(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) {
        let { id, value } = e.currentTarget as typeof e.currentTarget & { id: keyof IForm };
        setAppState((prevState: any) => {
            let newState = prevState;
            newState.contactForm[id].value = value;
            return newState;
        });
        // console.log(form);
    }

    function sendForm() {
        let newForm = form;
        let problems = false;
        if (form.name.value.length < 1) {
            newForm.name.errorMsg = "Please enter your name :)";
            problems = true;
        } else if (form.name.value.length < 2) {
            newForm.name.errorMsg = "Surely there's more... :o";
            problems = true;
        } else {
            newForm.name.errorMsg = "";
        }
        if (form.email.value.length < 1) {
            newForm.email.errorMsg = "Please enter your email address :)";
            problems = true;
        } else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(form.email.value)) {
            newForm.email.errorMsg = "This email format is incorrect :O";
            problems = true;
        } else {
            newForm.email.errorMsg = "";
        }
        if (form.message.value.length < 1) {
            newForm.message.errorMsg = "Please enter your message :)";
            problems = true;
        } else if (form.message.value.split(" ").length < 2) {
            newForm.message.errorMsg = "At least share three words :O";
            problems = true;
        } else {
            newForm.message.errorMsg = "";
        }

        if (!problems) {
            setAppState((prevState: any) => {
                let nwState = prevState;
                (Object.keys(prevState.contactForm) as Array<keyof IForm>).forEach(key => nwState.contactForm[key].errorMsg = "");
                return nwState;
            });
            setDummy(dummy + 1);
            // console.log("Good to go!");
            // console.log(form);
            api
                .post('/api.php', {
                    action: 'reach-out',
                    body: {
                        subject: 'New Message from your Profile Page!',
                        message: `Name: ${form.name.value} \n`
                            + `Subject: ${form.subject.value} \n`
                            + `Email: ${form.email.value} \n`
                            + `Message: ${form.message.value} \n`
                    }
                })
                .then(function (response) {
                    // console.log('SUCCESS:');
                    // console.log(response);
                    if (response.status) {
                        setAppState((prevState: any) => ({ ...prevState, contactFormSubmitted: true }));
                    } else {
                        setAppState((prevState: any) => ({ ...prevState, contactFormError: "Something went wrong. Try again." }));
                        console.log(response);
                    }
                })
                .catch(function (error) {
                    setAppState((prevState: any) => ({ ...prevState, contactFormError: "Something went wrong. Try again." }));
                    console.log(error);
                });
        } else {
            console.log("Invalid form: ");
            console.log(newForm);
            setAppState((prevState: any) => {
                let newState = prevState;
                newState.contactForm = newForm;
                return newState;
            });
            setDummy(dummy + 1);
        }
    }

    return (
        <>
            <Button
                bg="none"
                _active={{}}
                _focus={{}}
                _hover={{ textShadow: "2px -5px #555", color: "#FFF" }}
                onClick={() => {
                    setAppState((prevState: any) => {
                        let newState = prevState;
                        newState.contactForm = initialForm;
                        return newState;
                    });
                    setAppState((prevState: any) => ({ ...prevState, contactFormError: "" }));
                    setDummy(dummy + 1);
                    modalDisclosure.onOpen();
                }}
            >
                <Text color="whitesmoke" fontSize={28} fontFamily="Unica One">
                    Contact Me
                </Text>
            </Button>

            <Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader><Text fontFamily="Unica One" id={"reach-out-" + dummy}>Reach out</Text></ModalHeader>
                    {/* <ModalCloseButton /> */}
                    <ModalBody>
                        <Grid templateColumns="repeat(5, 1fr)" gap={1}>
                            {
                                (Object.keys(form) as Array<keyof IForm>).map((key, inpI) => {
                                    const inp = form[key];
                                    return (
                                        <GridItem key={key} colSpan={5} >
                                            <FormControl
                                                id={key}
                                                isRequired={inp.required}
                                                isInvalid={inp.errorMsg.length > 0}
                                                isDisabled={appState.contactFormSubmitted}
                                            >
                                                <FormLabel>{inp.label}</FormLabel>
                                                {
                                                    inp.type === "textarea"
                                                        ? <Textarea
                                                            placeholder={inp.placeholder}
                                                            onChange={handleChange}
                                                        />
                                                        : <Input
                                                            placeholder={inp.placeholder}
                                                            type={inp.type.length > 0 ? inp.type : "text"}
                                                            onChange={handleChange}
                                                        />
                                                }
                                                {
                                                    inp.errorMsg.length > 0 &&
                                                    <FormErrorMessage>{inp.errorMsg}</FormErrorMessage>
                                                }
                                            </FormControl>
                                        </GridItem>
                                    );
                                })
                            }
                        </Grid>

                    </ModalBody>

                    <ModalFooter>
                        {appState.contactFormError.length > 0 &&
                            <Text mr={3} color="crimson" fontWeight={600} fontSize={14}>{appState.contactFormError}</Text>
                        }
                        {
                            appState.contactFormSubmitted !== false &&
                            <Text mr={3} color="#006b0c" fontWeight={600} fontSize={14} wordBreak="break-word">{"Thanks for reaching out :). I'll get back to you ASAP!"}</Text>
                        }
                        <Button
                            bg="background"
                            _hover={{ bg: "white", boxShadow: "2px -5px #4a5568", color: "#4a5568" }}
                            mr={3}
                            onClick={modalDisclosure.onClose}
                            fontFamily="Raleway"
                            color="whitesmoke"
                            fontSize={14}
                        >
                            Close
                        </Button>
                        <Button
                            variant="ghost"
                            fontFamily="Unica One"
                            color="gray.600"
                            _focus={{}}
                            _hover={{ boxShadow: "2px -5px #000724", fontWeight: "700", color: "#000724" }}
                            isDisabled={appState.contactFormSubmitted}
                            onClick={sendForm}
                        >
                            Send
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

