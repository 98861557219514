import { FaReact, FaNodeJs } from "react-icons/fa";
import {
    SiTypescript,
    SiJavascript,
    SiFlutter,
    SiAndroid,
    SiGitlab,
    SiGithub,
    SiMongodb,
    SiMicrosoftteams,
    SiJira,
    SiMeteor,
    SiExpo,
    SiVisualstudio,
    SiCsharp,
    SiPhp,
    SiMysql,
    SiAsana,
    SiSwift,
    SiJava,
    SiXcode,
    SiAzuredevops
} from "react-icons/si";

import { MdSwapVert } from "react-icons/md";

import { IExperience } from "../resources/interfaces";

const experiences: Array<IExperience> = [
    // {
    //     company: 'FNB Core Banking Solutions',
    //     description: 'The banks core systems department',
    //     dateFrom: new Date('2015-10-01'),
    //     dateTo: new Date('2017-11-27'),
    //     role: 'Test Analyst',
    //     projects: []
    // },
    {
        company: 'Freelance',
        description: 'Freelance web developer, providing custom features to template/Wordpress websites, building out single page applications and pages for small business and NPOs, and mobile applications.',
        dateFrom: new Date('2017-12-01'),
        dateTo: new Date('2018-12-01'),
        role: 'Software developer',
        projects: [
            {
                title: 'App for exchange of high quality fashion items',
                description: 'A marketplace for people and merchants to buy and sell quality fashion items',
                role: 'Lead developer',
                responsibilities: [
                    "Building out Android application including sql database, browse/search page, favourites page, payment integration and more",
                    "Consuming API's from back-end and dynamically displaying content",
                    "Integrating with payment trust service for secure payments,",
                    "Creating mobile app specific back-end in PHP and SQL",
                    "Setting up Github version control"
                ],
                teamSize: 5,
                dateFrom: new Date('2018-07-01'),
                dateTo: new Date('2018-11-01'),
                tech: [
                    { label: 'Android SDK', icon: SiAndroid },
                    { label: 'Java', icon: SiJava },
                    { label: 'PHP', icon: SiPhp },
                    { label: 'MySQL', icon: SiMysql },
                    { label: 'Github', icon: SiGithub }
                ],
                notable: true
            },
        ]
    },
    {
        company: 'SigloNet',
        description: 'Software development company operating in Mozambique and South Africa using various web and mobile app technologies to digitalise and improve business processes.',
        dateFrom: new Date('2018-12-01'),
        dateTo: new Date('2020-11-01'),
        role: 'Software developer',
        projects:[
            {
                title: 'E-Learning app for cyber-security',
                description: 'An e-learning mobile application which provided video, audio and document course material to users',
                role: 'Lead developer',
                responsibilities: [
                    "Building out Android and IOS applications",
                    "Co-creating back-end enpoints for course content",
                    "Building out video and audio player with background service",
                    "Creating a PDF reader for course transcripts",
                    "Creating multiple choice activities for each course"
                ],
                teamSize: 5,
                dateFrom: new Date('2019-10-01'),
                dateTo: new Date('2020-02-01'),
                tech: [
                    { label: 'Android SDK', icon: SiAndroid },
                    { label: 'Java', icon: SiJava },
                    { label: 'IOS SDK', icon: SiXcode },
                    { label: 'Swift', icon: SiSwift },
                    { label: 'PHP', icon: SiPhp },
                    { label: 'MySQL', icon: SiMysql }
                ],
                notable: true
            },
            {
                title: 'Land survayence tool',
                description: '',
                role: 'Mobile app developer',
                responsibilities: [
                    "Building out Android applicication",
                    "Handling requests and responses from app to endpoint",
                    "Building GPS location service and optimising accuracy",
                    "Pushing changes to version control"
                ],
                teamSize: 4,
                dateFrom: new Date('2020-06-01'),
                dateTo: new Date('2020-09-01'),
                tech: [
                    { label: 'Flutter', icon: SiFlutter },
                    { label: 'PHP', icon: SiPhp },
                    { label: 'MySQL', icon: SiMysql },
                    { label: 'Github', icon: SiGithub },
                    { label: 'Asana', icon: SiAsana }
                ],
                notable: false
            },
            {
                title: 'Claims management platform',
                description: 'Building and maintaining a claims management platform for an insurance company',
                role: 'Front-end developer',
                responsibilities: [
                    "Building out pages and navigation features",
                    "Using enpoints for user driven CRUD operations",
                    "Creating search and filter features for content",
                    "Building out role dependant UI and access",
                    "Merging, building and deploying changes through version control and CI/CD pipelines"
                ],
                teamSize: 6,
                dateFrom: new Date('2020-09-01'),
                dateTo: new Date('2020-12-01'),
                tech: [
                    { label: 'React JS', icon: FaReact },
                    { label: 'Azure DevOps', icon: SiAzuredevops },
                    { label: 'Microsoft Teams', icon: SiMicrosoftteams },
                    { label: 'Jira', icon: SiJira }
                ],
                notable: true
            }
        ]
    },
    {
        company: 'Param Technologies',
        description: 'A javascript-first software development company based in Johannesburg, South Africa with an inclination for modern technologies.',
        dateFrom: new Date('2020-12-01'),
        dateTo: new Date(),
        role: 'Software developer',
        projects: [
            {
                title: 'React One-pager',
                description: 'Built a one-pager for an Italian company from scratch.',
                contractor: "CodeSwop",
                role: 'Front-end developer',
                responsibilities: [
                    'Scaffolding and structuring new project',
                    'Building layouts to design specifications',
                    'Building logic to business requirements',
                    'Email functionality using APIs for contact form',
                    'Form validation logic',
                    'Responsive design from mobile to ultra wide monitors',
                    'Deploying to version control'
                ],
                teamSize: 2,
                dateFrom: new Date('2021-01-02'),
                dateTo: new Date('2021-02-01'),
                tech: [
                    { label: 'React JS', icon: FaReact },
                    { label: 'Typescript', icon: SiTypescript },
                    //{ label: 'NPM', icon: SiNpm },
                    { label: 'Gitlab', icon: SiGitlab },
                    { label: 'Microsoft Teams', icon: SiMicrosoftteams }
                ],
                notable: false
            },
            {
                title: 'Gigs platform',
                description: 'Built on and maintained a web platform where users can book or offer gigs.',
                contractor: "CodeSwop",
                role: 'Full-stack developer',
                responsibilities: [
                    'Built and maintained front-end based on assigned tasks and Gitlab issues',
                    'Updating schema and routing in back-end for new features and maintenance',
                    'Updating layouts to design specifications',
                    'Pushing code to repo, and building and deploying to dev environment through CI/CD processes'
                ],
                teamSize: 5,
                dateFrom: new Date('2021-02-02'),
                dateTo: new Date('2021-03-01'),
                tech: [
                    { label: 'Meteor JS', icon: SiMeteor },
                    { label: 'React JS', icon: FaReact },
                    { label: 'Node.JS', icon: FaNodeJs },
                    { label: 'Javascript', icon: SiJavascript },
                    { label: 'Gitlab', icon: SiGitlab },
                    { label: 'Microsoft Teams', icon: SiMicrosoftteams }
                ],
                notable: true
            },
            {
                title: 'Mobile payment for transit app',
                description: 'Built React Native front-end and NodeJS back-end components and services',
                architecture: 'Microservices',
                designPattern: 'MVC',
                role: 'Full-stack developer',
                responsibilities: [
                    'Creating routing and layouts for React Native front-end',
                    'Building back-end services for payments, user wallets, transaction history, and transit search.',
                    'Updating layouts to design specifications',
                    'Delegating tasks to team members',
                    'Setting up version control',
                    'Deploying to version control and automated CI/CD processes'
                ],
                teamSize: 6,
                dateFrom: new Date('2021-03-02'),
                dateTo: new Date('2021-06-01'),
                tech: [
                    { label: 'React Native', icon: FaReact },
                    { label: 'Expo', icon: SiExpo },
                    { label: 'Node.JS', icon: FaNodeJs },
                    { label: 'MongoDB', icon: SiMongodb },
                    { label: 'Javascript', icon: SiJavascript },
                    { label: 'Github', icon: SiGithub },
                    { label: 'Jira', icon: SiJira }
                ],
                notable: false
            },
            {
                title: 'Development on internal telecoms system',
                description: 'Development and maintenance of front-end system for internal staff of telecoms comapany',
                contractor: "Altron",
                role: 'Front-end developer',
                responsibilities: [
                    'Building out functional specs',
                    'Addressing service requests mainly for fixes',
                    'Performed front-end technical inspections for compliance documentation'
                ],
                teamSize: 8,
                dateFrom: new Date('2021-06-01'),
                dateTo: new Date(),
                tech: [
                    { label: 'ASP.NET WebForms', icon: SiVisualstudio },
                    { label: 'C#', icon: SiCsharp },
                    { label: 'Microsoft Teams', icon: SiMicrosoftteams },
                    { label: 'Mercurial', icon: MdSwapVert },
                    { label: 'Jira', icon: SiJira }
                ],
                notable: false
            },
            {
                title: 'Front-end development for loans platform',
                description: 'Built out fron-end features',
                contractor: "BBD",
                architecture: 'Microservices',
                role: 'Front-end developer',
                responsibilities: [
                    'Building and maintaining front-end code for various services based on project board tickets',
                    'Managing code on git repos (pull from dev branch, make changes, push to new feature branch, create pull request, have code reviewed, merge)',
                    'Building and deploying to dev environment',
                    'Testing in dev environment and deploying to test environment'
                ],
                teamSize: 15,
                dateFrom: new Date('2021-06-09'),
                dateTo: new Date(),
                tech: [
                    { label: 'React JS', icon: FaReact },
                    { label: 'Javascript', icon: SiJavascript },
                    { label: 'Azure DevOps', icon: SiAzuredevops },
                    { label: 'Microsoft Teams', icon: SiMicrosoftteams }
                ],
                notable: true
            }
        ]
    }
];

export default experiences;