
import {
    Image,
} from "@chakra-ui/react";

interface IProps {
    image: any;
    direction: -1|1;
    initOffset: number;
    moveCloud(newOffset: number): void;
}

export default function DriftingCloud({ image, direction, initOffset, moveCloud } : IProps) {

    // let timer;

    // function moveCloud() {
    //     updateOffset(offset + Math.round(Math.random()));
    // }

    return (
        <Image
            animation={`drift-right-${Math.floor((Math.random() * 6) + 1)} ${Math.round((Math.random() * 144) + 48)}s 2`}
            pos="relative"
            left={direction === -1 ? initOffset : 0}
            right={direction === 1 ? initOffset : 0}
            maxH="80px"
            opacity={(Math.random() * 0.4) + 0.6}
            className="vector-shadow drifting-cloud"
            src={image}
        />
    );
}