import { useState } from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Center,
    Collapse,
    Divider,
    Flex,
    Grid,
    GridItem,
    Image,
    Text,
    SlideFade,
    ListItem,
    UnorderedList
} from "@chakra-ui/react";
import {
    BsPeopleFill
} from "react-icons/bs";
import { MdNavigateBefore } from "react-icons/md";
import styles from "../../resources/styles";
import experiences from "../../data/experience";
import passions from "../../data/passions";
import Footer from "../../components/Footer";



export default function Projects() {

    const [projectType, setProjectType] = useState<"work" | "passion">("work");
    const [selectedProj, setSelectedProj] = useState<null | string>(null);
    const [hoveredProj, setHoveredProj] = useState<null | string>(null);

    let projSource = projectType === "work" ? experiences : passions;

    return (
        <Box
            {...styles.page}
            px={3}
            overflowX="hidden"
        >
            <Flex>
                <Link to="/">
                    <Text
                        my={3}
                        mx={2}
                        px={6}
                        color="#CCC"
                        fontFamily="Unica One"
                        fontSize={24}
                        _hover={{ textDecorationColor: "whitesmoke", textDecor: "underline" }}
                    >
                        <MdNavigateBefore style={{ display: 'inline-block' }} />Home Page
                    </Text>
                </Link>
            </Flex>
            <Text
                fontSize={36}
                fontFamily="Unica One"
                color="whitesmoke"
                my={4}
                fontWeight={700}
            >
                Projects
            </Text>
            <Box px={3} h="100%">
                <Flex>
                    <Box
                        flex={1}
                    >
                        <Text
                            pr={2}
                            color="whitesmoke"
                            cursor="pointer"
                            opacity={projectType === "work" ? 1 : 0.5}
                            fontFamily={"Unica One"}
                            fontSize={projectType === "work" ? 21 : 16}
                            textDecor={projectType === "work" ? "underline" : "none"}
                            textAlign="right"
                            onClick={() => { setProjectType("work") }}
                        >
                            Work Projects
                        </Text>
                    </Box>
                    <Box
                        flex={1}
                    >
                        <Text
                            pl={2}
                            color="whitesmoke"
                            cursor="pointer"
                            opacity={projectType === "passion" ? 1 : 0.5}
                            fontFamily={"Unica One"}
                            fontSize={projectType === "passion" ? 21 : 16}
                            textDecor={projectType === "passion" ? "underline" : "none"}
                            onClick={() => { setProjectType("passion") }}
                        >
                            Passion Projects
                        </Text>
                    </Box>
                </Flex>
                <Box>
                    {projSource.slice().reverse().map((exp, expIdx) => {
                        //const projDisclosure = useDisclosure();
                        return (
                            <SlideFade key={expIdx} in={true}>
                                {exp.image && <Image src={exp.image} w={120} mt={6} mb={-6} />}
                                <Text fontFamily="Unica One" color="whitesmoke" fontSize={20} fontWeight={500} mt={6}>
                                    {exp.company}
                                </Text>
                                <Text fontFamily="Unica One" color="#ccc" fontSize={16} fontWeight={100} letterSpacing={2.4}>{exp.role}</Text>
                                <Text
                                    color="whitesmoke"
                                    fontSize={12}
                                    fontWeight={100}
                                    letterSpacing={3}
                                >
                                    {exp.dateFrom.toISOString().substr(0, 7).replace('-', '/')} to {exp.dateTo.toISOString().substr(0, 7).replace('-', '/')}
                                </Text>
                                <Divider />
                                <Grid templateColumns="repeat(9, 1fr)" gap={4} my={4}>
                                    {/* {['greens', 'beans', 'brotato', 'tamatie'].map((f4colors) => (
                                    <GridItem colSpan={2} h="10" bg="tomato"><Text color="whitesmoke">{f4colors}</Text></GridItem>
                                    ))} */}
                                    {exp.projects.slice().reverse().map((proj, projIdx) => {
                                        return (
                                            <GridItem
                                                key={expIdx + "_" + projIdx}
                                                colSpan={[9, 5, 3]}
                                            >
                                                <Box
                                                    bg="white"
                                                    p={[2, 3, 4]}
                                                    borderRadius={12}
                                                    cursor="pointer"
                                                    onMouseEnter={() => {
                                                        setHoveredProj(`${projectType}_${expIdx}_${projIdx}`);
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHoveredProj(null);
                                                    }}
                                                    onClick={() => {
                                                        setSelectedProj(
                                                            selectedProj === `${projectType}_${expIdx}_${projIdx}` ?
                                                                null :
                                                                `${projectType}_${expIdx}_${projIdx}`
                                                        );
                                                    }}
                                                >
                                                    <Text
                                                        fontWeight={selectedProj === `${projectType}_${expIdx}_${projIdx}` ? 800 : 600}
                                                        //fontSize={selectedProj === `${projectType}_${expIdx}_${projIdx}` ? 20 : 16}
                                                        textShadow={selectedProj === `${projectType}_${expIdx}_${projIdx}` ? "1px -2px #DDD" : "none"}
                                                    >
                                                        {proj.title}
                                                    </Text>
                                                    <Text fontFamily="Unica One" fontSize={17}>{proj.role}</Text>
                                                    <Text fontSize={12}
                                                        fontWeight={600}
                                                        letterSpacing={3}
                                                    >
                                                        {proj.dateFrom.toISOString().substr(0, 7).replace('-', '/')} to {proj.dateTo.toISOString().substr(0, 7).replace('-', '/')}
                                                    </Text>
                                                    <Collapse in={
                                                        selectedProj === `${projectType}_${expIdx}_${projIdx}`
                                                        || hoveredProj === `${projectType}_${expIdx}_${projIdx}`
                                                    }>
                                                        <Text
                                                            my={2}
                                                            fontSize={14}
                                                            fontWeight={600}
                                                            color={selectedProj === `${projectType}_${expIdx}_${projIdx}` ? "background" : "#777"}
                                                        >
                                                            {proj.description}
                                                        </Text>
                                                        <Text
                                                            my={2}
                                                            fontSize={12}
                                                            fontWeight={700}
                                                            textDecor="underline"
                                                            color={selectedProj === `${projectType}_${expIdx}_${projIdx}` ? "background" : "#777"}
                                                        >
                                                            Responsibilities:
                                                        </Text>
                                                        <UnorderedList color={selectedProj === `${projectType}_${expIdx}_${projIdx}` ? "background" : "#777"}>
                                                            {proj.responsibilities.map((resp, respIdx) => (
                                                                <ListItem key={respIdx}>
                                                                    <Text color="#666" fontSize={14} fontWeight={600}>
                                                                        {resp}
                                                                    </Text>
                                                                </ListItem>
                                                            ))}
                                                        </UnorderedList>
                                                        <Text my={2} mb={0} fontSize={12} fontWeight={700} textDecor="underline">
                                                            Team of:
                                                        </Text>
                                                        <BsPeopleFill
                                                            style={{ display: "inline-block", marginLeft: 6 }}
                                                            size={24}
                                                            color="#888"
                                                        />
                                                        <Text display="inline-block" my={2} mt={0} fontSize={20} fontFamily="Unica One" fontWeight={700}>
                                                            {proj.teamSize}
                                                        </Text>
                                                    </Collapse>
                                                    <Flex flexWrap="wrap">
                                                        {proj.tech.map((tech, techIdx) => techIdx < 5
                                                            || selectedProj === `${projectType}_${expIdx}_${projIdx}`
                                                            || hoveredProj === `${projectType}_${expIdx}_${projIdx}`
                                                            ? (
                                                                <Box key={techIdx} my={2} flex={1} mx={2}>
                                                                    <Center>
                                                                        <tech.icon size={21} color="#000724" />
                                                                    </Center>
                                                                    <Center>
                                                                        <Text color="#000724" textAlign="center" fontSize={12} fontWeight={600} opacity={0.7}>{tech.label}</Text>
                                                                    </Center>
                                                                </Box>
                                                            ) : <></>)}
                                                    </Flex>
                                                </Box>
                                            </GridItem>
                                        );
                                    })}
                                </Grid>

                            </SlideFade>
                        );
                    })}
                </Box>
                <Footer />
            </Box>
        </Box>
    );
}