import React from "react";
import {
    Collapse,
    Box,
    Text,
    useMediaQuery
} from "@chakra-ui/react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    TimelineDot
} from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import experiences from '../../data/experience';
import { MdNavigateNext } from "react-icons/md";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        backgroundColor: theme.palette.secondary.main,
    },
}))

export default function ProjectTimeline() {
    const classes = useStyles();
    let lastDate = new Date();

    const history = useHistory();
    const [isLessThan426] = useMediaQuery("(max-width: 425px)");

    return (
        <>
            <Timeline align="alternate">
                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            <Text color="whitesmoke">{(new Date()).toISOString().substr(0, 7)}</Text>
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color="secondary">
                            {/* <LaptopMacIcon /> */}
                        </TimelineDot>
                        <TimelineConnector className={classes.secondaryTail} />
                    </TimelineSeparator>
                    <TimelineContent style={isLessThan426 ? { flex: 10 } : {}}>
                        <Text color="whitesmoke">present</Text>
                    </TimelineContent>
                </TimelineItem>
                {isLessThan426 && <TimelineItem style={{ minHeight: 0, height: 0 }} />}
                {experiences.slice().reverse().map((exp, idx) => {
                    lastDate = exp.dateFrom;
                    return (
                        <React.Fragment key={idx}>
                            <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant="body2" color="textSecondary">
                                        <Text color="whitesmoke">{exp.dateTo.toISOString().substr(0, 7)}</Text>
                                    </Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant={idx > 0 ? "default" : "outlined"}>
                                        {/* <FastfoodIcon /> */}
                                    </TimelineDot>
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent style={isLessThan426 ? { flex: 10 } : {}}>
                                    <Paper elevation={3} className={classes.paper}>
                                        <Box style={exp.projects.length > 0 ? { cursor: 'pointer' } : {}}>
                                            <Typography variant="h6" component="h1">
                                                <Text fontWeight={600}>{exp.company}</Text>
                                            </Typography>
                                            <Typography><Text fontFamily="Unica One">{exp.role ?? ''}</Text></Typography>
                                        </Box>
                                        {exp.projects.length > 0 &&
                                            <Collapse in={true}>
                                                <Text fontSize={14} p={3}>
                                                    {exp.description}
                                                </Text>
                                                <Text
                                                    color="background"
                                                    _hover={{ textDecor: 'underline' }}
                                                    fontWeight={500}
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        history.push("/projects");
                                                        // console.log(`clicked ${exp.company} projects`);
                                                    }}
                                                >
                                                    <MdNavigateNext style={{ display: 'inline-block' }} />View Projects
                                                </Text>
                                            </Collapse>
                                        }
                                    </Paper>
                                </TimelineContent>
                            </TimelineItem>
                            {isLessThan426 && <TimelineItem style={{ minHeight: 0, height: 0 }} />}
                        </React.Fragment>
                    );
                })}
                <TimelineItem>
                    <TimelineOppositeContent>
                        <Typography variant="body2" color="textSecondary">
                            <Text color="whitesmoke">{lastDate.toISOString().substr(0, 7)}</Text>
                        </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot color="primary">
                            {/* <LaptopMacIcon /> */}
                        </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent style={isLessThan426 ? { flex: 10 } : {}}>
                    </TimelineContent>
                </TimelineItem>
            </Timeline>
        </>
    );
}