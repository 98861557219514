import { FaNodeJs } from "react-icons/fa";
import {
    SiFlutter,
    SiAndroid,
    SiGithub,
    SiMongodb,
    SiSlack,
    SiJira,
    SiPhp,
    SiMysql,
    SiAsana,
    SiJava,
} from "react-icons/si";

import { IExperience } from "../resources/interfaces";

import gcLogo from '../assets/logos/grubcrunch.png';
import moyaLogo from '../assets/logos/moya_blue.svg';

const passions: Array<IExperience> = [
    {
        company: 'GrubCrunch',
        description: 'A start-up founded by food-lovers with a goal to make affordable food more accessible, and to lower food wastage.',
        image: gcLogo,
        dateFrom: new Date('2018-12-01'),
        dateTo: new Date('2019-12-01'),
        role: 'Technical co-founder',
        projects: [
            {
                title: 'GrubCrunch App',
                description: 'A social food ordering platorm which offered delicious foods at affordable prices to share with friends or colleagues.',
                role: 'Lead developer',
                responsibilities: [
                    "Building out the mobile application",
                    "Co-creating back-end endpoints",
                    "Managing app versioning",
                    "Release management on Google Play Store"
                ],
                teamSize: 3,
                dateFrom: new Date('2018-12-01'),
                dateTo: new Date('2019-12-01'),
                tech: [
                    { label: 'Android SDK', icon: SiAndroid },
                    { label: 'Java', icon: SiJava },
                    { label: 'PHP', icon: SiPhp },
                    { label: 'MySQL', icon: SiMysql },
                    { label: 'Github', icon: SiGithub },
                    { label: 'Asana', icon: SiAsana }
                ]
            },
            {
                title: 'GrubCrunch Restaurants App',
                description: 'A platorm for restaurants to list and manage food items, create discounts, and track user engagement on the GrubCruch App.',
                role: 'Lead developer',
                responsibilities: [
                    "Building out the mobile application",
                    "Co-creating back-end endpoints",
                    "Managing app versioning",
                    "Release management on Google Play Store"
                ],
                teamSize: 3,
                dateFrom: new Date('2019-03-01'),
                dateTo: new Date('2019-12-01'),
                tech: [
                    { label: 'Android SDK', icon: SiAndroid },
                    { label: 'Java', icon: SiJava },
                    { label: 'PHP', icon: SiPhp },
                    { label: 'MySQL', icon: SiMysql },
                    { label: 'Github', icon: SiGithub },
                    { label: 'Asana', icon: SiAsana }
                ]
            },
        ]
    },
    {
        company: 'Moya Financial Services Group',
        description: 'A smart money manager for self-employed individuals, allowing them to track their cashflow, manage their expenses and easily send invoices, so they can worry less about their finances and more about the growth of their business.',
        url: 'www.moya.africa',
        image: moyaLogo,
        dateFrom: new Date('2019-10-01'),
        dateTo: new Date(),
        role: 'Technical co-founder',
        projects: [
            {
                title: 'Moya MVP 1 (Alpha release)',
                description: 'A mobile application where SME owners could link their bank account and organise and categorise their finances.',
                role: 'Lead developer',
                responsibilities: [
                    "Building out the mobile application",
                    "Building back-end services",
                    "Linking application to Stitch.money for bank account integration",
                    "Managing app versioning",
                    "Release management on Google Play Store"
                ],
                teamSize: 3,
                dateFrom: new Date('2020-08-01'),
                dateTo: new Date('2021-02-01'),
                tech: [
                    { label: 'Flutter SDK', icon: SiFlutter },
                    { label: 'Node.js', icon: FaNodeJs },
                    { label: 'MongoDB', icon: SiMongodb },
                    { label: 'Github', icon: SiGithub },
                    { label: 'Jira', icon: SiJira }
                ]
            },
            {
                title: 'Moya MVP 2 (in development)',
                description: 'A mobile application where self-employed individuals can link their bank account to view their finances, organise and track month-tomonth expenses, and generate digital invoices and recieve payments.',
                role: 'Lead developer',
                responsibilities: [
                    "Building out the mobile application",
                    "Building back-end services",
                    "Linking application to Stitch.money for bank account integration",
                    "Linking application to payment gateway for invoice payments",
                    "Managing app versioning",
                    "Release management on Google Play Store"
                ],
                teamSize: 3,
                dateFrom: new Date('2021-06-01'),
                dateTo: new Date(),
                tech: [
                    { label: 'Flutter SDK', icon: SiFlutter },
                    { label: 'Node.js', icon: FaNodeJs },
                    { label: 'MongoDB', icon: SiMongodb },
                    { label: 'Github', icon: SiGithub },
                    { label: 'Jira', icon: SiJira },
                    { label: 'Slack', icon: SiSlack }
                ]
            }
        ]
    },
];

export default passions;