import { Link } from "react-router-dom";
import {
    Box,
    Center,
    Flex,
    Image,
    Text
} from "@chakra-ui/react";
import { MdLocationOn } from "react-icons/md";
import { MdNavigateNext } from "react-icons/md";
import { FaReact, FaNodeJs } from "react-icons/fa";
import {
    SiTypescript,
    SiFlutter,
    SiAndroid,
    SiGithub,
    SiMongodb,
    SiSlack,
    SiMicrosoftteams,
    SiJira
} from "react-icons/si";
import ProjectTimeline from "../../components/ProjectTimeline";
import profileImg from "../../assets/images/profile1.jpg";
import styles from "../../resources/styles";
import strings from "../../data/strings";
import Footer from "../../components/Footer";

export default function Main() {

    const webStack = [
        { label: "React.js", icon: FaReact },
        { label: "Node.js", icon: FaNodeJs },
        { label: "Typescript", icon: SiTypescript },
        { label: "MongoDB", icon: SiMongodb },
    ];

    const mobileStack = [
        { label: "Flutter", icon: SiFlutter },
        { label: "Android SDK", icon: SiAndroid }
    ];

    const projectStack = [
        { label: "Github", icon: SiGithub },
        { label: "Slack", icon: SiSlack },
        { label: "Jira", icon: SiJira },
        { label: "Microsoft Teams", icon: SiMicrosoftteams },
    ];

    return (
        <Box
            {...styles.page}
            overflowX="hidden"
        >
            <Flex color="#777" boxShadow="xl" bg="whitesmoke" w="100%" borderLeftRadius={24} p={[4, 4, 6]} ml={[4, 18, 36]} flexWrap="wrap">
                <Box flex={2} minW={260}>
                    <Text fontFamily="Unica One" fontSize="26px">{strings.greetingCard.greeting}</Text>
                    <Text fontFamily="Unica One" fontSize="32px">{strings.greetingCard.mainRole}</Text>
                    <Flex>
                        <Box><MdLocationOn size={40} /></Box>
                        <Box alignSelf="center"><Text fontFamily="Unica One">{strings.greetingCard.location}</Text></Box>
                    </Flex>
                    <Text fontWeight={300} px={3} mt={6}>
                        {strings.greetingCard.message}
                    </Text>
                </Box>
                <Flex justifyContent={["center", "center", "left"]} flex={1} alignSelf="center" pos="relative" top={[0, 0, 0]} minW={160}>
                    <Image mt={[6, 0, 0]} borderRadius={400} boxShadow="dark-lg" maxW={[200, 200, 288]} w={[48, 48, "80%"]} src={profileImg/*"https://pbs.twimg.com/profile_images/916755075636367360/CI7tJ12_.jpg"*/} />
                </Flex>
            </Flex>

            <Flex flexWrap="wrap">
                <Box flex={2} mt={12} mr={[4, 0, 0]} minW={260}>
                    <Box boxShadow="xl" bg="whitesmoke" w="100%" borderRightRadius={24} p={6}>
                        <Text fontFamily="Unica One" fontSize="26px" mb={4}>
                            {strings.myStackCard.title}
                        </Text>
                        {strings.myStackCard.message.map((txt, txtIdx) => (<Text key={txtIdx} my={3}>{txt}</Text>))}
                    </Box>
                    <Flex>
                        <Link to="/projects">
                            <Text
                                my={3}
                                mx={2}
                                p={6}
                                color="#CCC"
                                fontFamily="Unica One"
                                fontSize={24}
                                _hover={{ textDecorationColor: "whitesmoke", textDecor: "underline" }}
                            >
                                View Projects
                                <MdNavigateNext style={{ display: 'inline-block' }} />
                            </Text>
                        </Link>
                    </Flex>
                </Box>
                <Flex flex={1} py={[2, 4, 8]}>
                    <Box flex={1}>
                        <Center>
                            <Text my={8} color="whitesmoke" opacity={0.6}>
                                Web
                            </Text>
                        </Center>
                        {webStack.map((tool, toolIdx) => (
                            <Box mb={8} key={toolIdx}>
                                <Center>
                                    <tool.icon size={30} color="whitesmoke" />
                                </Center>
                                <Center>
                                    <Text color="whitesmoke" fontSize={14} fontWeight={200} opacity={0.5}>{tool.label}</Text>
                                </Center>
                            </Box>
                        ))}
                    </Box>
                    <Box flex={1}>

                        <Center>
                            <Text my={8} color="whitesmoke" opacity={0.6}>
                                Mobile
                            </Text>
                        </Center>
                        {mobileStack.map((tool, toolIdx) => (
                            <Box mb={8} key={toolIdx}>
                                <Center>
                                    <tool.icon size={30} color="whitesmoke" />
                                </Center>
                                <Center>
                                    <Text color="whitesmoke" fontSize={14} fontWeight={200} opacity={0.5}>{tool.label}</Text>
                                </Center>
                            </Box>
                        ))}
                    </Box>
                    <Box flex={1}>
                        <Center>
                            <Text my={8} color="whitesmoke" opacity={0.6}>
                                Projects
                            </Text>
                        </Center>
                        {projectStack.map((tool, toolIdx) => (
                            <Box mb={8} key={toolIdx}>
                                <Center>
                                    <tool.icon size={30} color="whitesmoke" />
                                </Center>
                                <Center>
                                    <Text color="whitesmoke" fontSize={14} fontWeight={200} opacity={0.5}>{tool.label}</Text>
                                </Center>
                            </Box>
                        ))}
                    </Box>
                </Flex>
            </Flex>
            <Box>
                <ProjectTimeline />
            </Box>
            <Footer />
        </Box>
    );
}