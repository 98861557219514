import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: "#84cea3",
    primaryDark: "#00A961",
    accent: "#FFBF00",
    background: "#000724",
    bgLite: "#0e1f66",
    facebook: "#3b5998",
    google: "#FE2B26"
  },
  components: {
    Text: {
      baseStyle: {
        color: "#333",
        fontFamily: "Raleway",
      }
    }
  }
});

export default theme;
