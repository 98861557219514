import {
    Box,
    Flex,
    Link
} from "@chakra-ui/react";
import {
    AiFillLinkedin
} from "react-icons/ai";
import {
    SiGithub
} from "react-icons/si";
import ContactMeModal from "../ContactMeModal";

export default function Footer() {
    return (
        <Flex mt={8} px={3} float="right" flexWrap="wrap-reverse">
            <Link _focus={{}} href="https://www.linkedin.com/in/boipelo-zuma-682522136" isExternal>
                <Box
                    mx={3}
                    float="right"
                >
                    <AiFillLinkedin color="#f5f5f5" size={36} />
                </Box>
            </Link>
            <Link _focus={{}} href="https://github.com/PeloDev" isExternal>
                <Box
                    mx={3}
                    float="right"
                >
                    <SiGithub color="#f5f5f5" size={36} />
                </Box>
            </Link>
            <Box>
                <ContactMeModal />
            </Box>
        </Flex>
    );
}