import { createContext, useState } from 'react';

let initialState: any = {
    contactForm: {
        name: {
            value: "",
            label: "Your name",
            placeholder: "Your name",
            type: "",
            errorMsg: "",
            required: true
        },
        email: {
            value: "",
            label: "Email",
            placeholder: "your@email.com",
            type: "email",
            errorMsg: "",
            required: true
        },
        subject: {
            value: "",
            label: "Subject",
            placeholder: "Hi Boipelo!",
            type: "",
            errorMsg: "",
            required: false
        },
        message: {
            value: "",
            label: "Message",
            placeholder: "I saw your online profile and...",
            type: "textarea",
            errorMsg: "",
            required: true
        }
    },
    contactFormSubmitted: false,
    contactFormError: ""
};

export const AppContext = createContext(initialState);

export const StateProvider = (props: any) => {
    const [appState, setAppState] = useState(initialState);

    return (
        <AppContext.Provider value={[appState, setAppState]}>
            {props.children}
        </AppContext.Provider>
    );
}