const strings = {
    greetingCard: {
        greeting: "Hi, I'm Boipelo,",
        mainRole: "Web & Mobile Developer",
        location: " - based in Johannesburg, South Africa",
        message: "I'm a software developer with 4 years of mainly front-end web and mobile app development experience. I'm a big fan of javascript technologies (and Flutter!). I'll be using this site as my own developer profile for work I've done, and as a blog for tech and other things I'm passionate about."
    },
    myStackCard: {
        title: "My Stack",
        message: ["I'm primarily a front-end web developer. However I've worked on back-end and front-end for both web and mobile applications. I started with web and mobile development while studying, using vanilla HTML, JS and CSS for web apps, and Android SDK and Java for mobile apps.", "Through work experience and self-learning I've since been exposed to a variety of tools and technologies including PHP, MySQL, Swift, Typescript, Meteor.js and more. Today my preferred stacks involve React with typescript, Node.js + Express.js for back-end, yarn for package management, and Flutter for mobile apps."]
    },

};

export default strings;