import { useState } from "react";
import { Link } from "react-router-dom";
import {
    Box,
    Flex,
    Text,
} from "@chakra-ui/react";
import { MdNavigateBefore } from "react-icons/md";
import styles from "../../resources/styles";
import cloud1 from '../../assets/icons/clouds/cloud1.svg';
import cloud2 from '../../assets/icons/clouds/cloud2.svg';
import cloud3 from '../../assets/icons/clouds/cloud3.svg';
import cloud4 from '../../assets/icons/clouds/cloud4.svg';
import cloud5 from '../../assets/icons/clouds/cloud5.svg';
import cloud6 from '../../assets/icons/clouds/cloud6.svg';
import DriftingCloud from "../../components/DriftingCloud";


export default function NotFound() {

    const [cloudList, updateCloudList] = useState([
        { image: cloud1, initialOffset: 0 },
        { image: cloud2, initialOffset: 0 },
        { image: cloud3, initialOffset: 0 },
        { image: cloud4, initialOffset: 0 },
        { image: cloud5, initialOffset: 0 },
        { image: cloud6, initialOffset: 0 }
    ]);

    return (
        <Box
            {...styles.page}
            px={3}
            overflowX="hidden"
            overflowY="hidden"
        >
            <Flex>
                <Link to="/">
                    <Text
                        my={3}
                        mx={2}
                        px={6}
                        color="#CCC"
                        fontFamily="Unica One"
                        fontSize={24}
                        _hover={{ textDecorationColor: "whitesmoke", textDecor: "underline" }}
                    >
                        <MdNavigateBefore style={{ display: 'inline-block' }} />Home Page
                    </Text>
                </Link>
            </Flex>
            <Text
                fontSize={36}
                fontFamily="Unica One"
                color="whitesmoke"
                my={4}
                fontWeight={700}
                textAlign="center"
            >
                Page Not Found
            </Text>
            <Flex direction="column" maxH="500px">
                {
                    cloudList.map((cloud, idx) => {

                        return (
                            <Flex key={idx} justifyContent={"flex-start"}>
                                <DriftingCloud
                                    image={cloud.image}
                                    initOffset={cloud.initialOffset}
                                    direction={(idx + 2) % 2 === 1 ? -1 : 1}
                                    moveCloud={(newOffset: number) => {
                                        updateCloudList((prevList) => {
                                            let newList = prevList;
                                            newList[idx] = { ...newList[idx], initialOffset: newOffset }
                                            // console.log(newOffset);
                                            return newList;
                                        });
                                    }}
                                />
                            </Flex>
                        );
                    })
                }
            </Flex>
        </Box>
    );
}