const styles = {
    page: {
        backgroundColor: "background",
        backgroundImage: 'url("https://www.transparenttextures.com/patterns/cartographer.png")',
        h: "100vh",
        w: "100vw",
        py: 12,
    }
};

export default styles;